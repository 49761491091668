import React from 'react'
import { IS_DEV } from '../config'

export default function Code({ data }) {
    // return <></>;

    return !IS_DEV ? null : (
        <div dir="ltr">
            <hr />
            <code>
                {JSON.stringify(data)}
            </code>
        </div>
    )
}
