import React from 'react'
import styled from 'styled-components';
import Button from '../layout/Button';
import AnimatePop from './AnimatePop';
import GameFooter from './GameFooter';

const notEmpty = s => s.length > 0;



const GameTag = styled.div`
    h3 {
        color: ${props => props.theme.main};
        cursor: pointer;
    }
`;


const GameMeta = styled.ul`
    font-size:0.85em;
    list-style:none;
    padding: 0;
    li {
        display: inline-block;
        margin-inline-end: 1.3em;
        margin-block-end: 0.35em;
        strong {

            font-weight: normal;
        }
        strong {
            color: ${props => props.theme.main};
            /* ${props => props.theme.font.bold.normal} */
            font-variation-settings: "wght" 600, "wdth" 350;
        }
        ${props => props.theme.font.bold.semiCondensed}
            font-variation-settings: "wght" 500, "wdth" 300;
    }
`;

const Small = styled.p`
    opacity: 0.4;
    font-size:0.5em;
        /* ${props => props.theme.font.bold.normal} */
`;

const Attachment = styled.div`

`;


export default function FullGame({ game, closeFunc }) {


    return (!game ?
        <></> :
        <GameTag>
            <AnimatePop key={game.id}>
                <h3 onClick={closeFunc}>{game.name ? game.name : '[משחק ללא שם]'}</h3>
                {game.description ? `${game.description}`.split('\n\n').map((s, key) => <p key={key}>{s}</p>) : '[לא נכתב תיאור למשחק]'}
                {game.rationale && <div>
                    <h5>רציונל</h5>
                    {`${game.rationale}`.split('\n\n').map((s, key) => <p key={key}>{s}</p>)}
                </div>}

                <GameMeta>
                    {(game.category).filter(notEmpty).length ? <li><strong>{game.category.length === 1 ? 'בקטגוריה' : 'בקטגוריות'}:</strong> <span>{(game.category).filter(notEmpty).join(', ')}</span></li> : ''}
                    {(game.skills).filter(notEmpty).length ? <li><strong>{game.skills.length === 1 ? 'מיומנות' : 'מיומנויות'}:</strong> <span>{(game.skills).filter(notEmpty).join(', ')}</span></li> : ''}
                    {(game.level).filter(notEmpty).length ? <li><strong>רמת קושי:</strong> <span>{(game.level).filter(notEmpty).join(', ')}</span></li> : ''}
                    {(game.activityType).filter(notEmpty).length ? <li><strong>{game.skills.length === 1 ? 'סוגי הפעילות' : 'סוג הפעילות'}:</strong> <span>{(game.activityType).filter(notEmpty).join(', ')}</span></li> : ''}
                    {(game.compatability).filter(notEmpty).length ? <li><strong>מרחב:</strong> <span>{(game.compatability).filter(notEmpty).join(', ')}</span></li> : ''}
                    {(game.groupSize).filter(notEmpty).length ? <li><strong>גודל קבוצה:</strong> <span>{(game.groupSize).filter(notEmpty).join(', ')}</span></li> : ''}
                </GameMeta>


                {!game?.fetched ? (
                    <Small style={{ position: 'absolute', marginTop: '-0.4em', left: 0 }}>מחפשים קבצים נלווים</Small>
                ) :
                    game.attachments.length ?
                        <div>
                            <h4>קבצים נלווים למשחק:</h4>
                            {game.attachments.map((attachment, key) =>
                                <Attachment key={key}>
                                    <Button to={attachment.url}>קובץ נלווה להורדה {key + 1}</Button>
                                </Attachment>
                            )}
                        </div>
                        : null //<Small>(אין קבצים נלוים)</Small>
                }

                <GameFooter game={game} />

                {/* <div dir="ltr">
            <hr />
            <code>
                {JSON.stringify(game)}
            </code>
        </div> */}
            </AnimatePop>
        </GameTag>
    )
}
