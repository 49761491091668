export const gameFilter = ({ game, hiddenValues, gameTypes, searchBar }) => {


    // SEARCH FIRST
    if (searchBar && searchBar.length) {
        // console.log(game.name, searchBar);
        const foundString =
            (game.name).includes(searchBar) ||
            (game.description).includes(searchBar)
        if (!foundString) return false;
    }


    // FILTER AFTER

    const gameTypesIdsToHide = Object.keys(hiddenValues ? hiddenValues : {});
    // console.log('gameTypesIdsToHide', gameTypesIdsToHide);


    if (game.compatability.includes('אונליין'))
        console.log('games who are online', game.compatability);



    // This will hide the game if one of a filter of a gametype is active
    const gameShouldHide = gameTypesIdsToHide.filter(gameTypeIdToHide => {
        // console.log('game', game);
        // console.log('game[gameTypeIdToHide]', game[gameTypeIdToHide]);
        const gameValues = game[gameTypeIdToHide];
        const gameHiddenValues = gameValues.filter(
            (gameValue) => hiddenValues[gameTypeIdToHide].includes(gameValue)
        );
        // but only hide the game if ALL values are hidden. if one of the values are requested to be visible, then show the game anyway.
        return gameHiddenValues.length === gameValues.length ? true : false;
    }).length;
    if (gameShouldHide) return false;

    // If it doesn't need to hide nessecarily, then don't hide it
    return true;
}